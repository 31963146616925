import React from 'react'
import PropTypes from 'prop-types'
import ReactFC from 'react-fusioncharts'
import FusionCharts from 'fusioncharts'
import Charts from 'fusioncharts/fusioncharts.charts'
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
import PowerCharts from 'fusioncharts/fusioncharts.powercharts'
import TreeMap from 'fusioncharts/fusioncharts.treemap'
import { Loader } from 'utils/Loader'
import { CHART_ERROR } from 'static/charts'
import ChartError from './ChartError'

const FUSION_CHARTS_LICENSE_KEY =
  'AA-65A15wje1E5C1E4B1A17B2A3C1B1B1B3D1E2gvxC3E5qyuiI-7B6B1mA-13tF5H2E4J2A4D4B4C6E2E1F4E1H1A7C8A3cE-11B-9F2A2H2vB2D3D2knyE1C3C11gapB4E4D4kH-7C-21D7E4F4C1H5G1G1I4B6C1E3pzzC1G4AB3zD-13mD1D3G4dwB14XB6C6mmC-8D3J2A9B2C1E7E5F1C4D4B1B9A6B2d=='

export const FUSION_CHARTS_TYPE = {
  Doughnut2D: 'doughnut2d',
  Pie2D: 'pie2d',
  MSCombiDY2D: 'mscombidy2d',
  MSCombi2D: 'mscombi2d',
  MSStackedColumn2DLineDY: 'msstackedcolumn2dlinedy',
  MSStackedColumn2D: 'msstackedcolumn2d',
  StackedColumn2DLineDY: 'stackedcolumn2dlinedy',
  StackedColumn2DLine: 'stackedcolumn2dline',
  StackedColumn2D: 'stackedcolumn2d',
  StackedArea2DLineDY: 'stackedarea2dlinedy',
  StackedArea2D: 'stackedarea2d',
  StackedBar2D: 'stackedbar2d',
  StackedBar2DWithLine: 'msstackedcolumn2dlinedy',
  TreeMap: 'treemap'
}

FusionCharts.options.license({
  key: FUSION_CHARTS_LICENSE_KEY,
  creditLabel: false
})
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme, PowerCharts, TreeMap)

function Chart({
  type,
  dataSource,
  width = '100%',
  height = '100%',
  dataFormat = 'json',
  isLoading = false,
  error
}) {
  return isLoading ? (
    <Loader centered />
  ) : error ? (
    <ChartError error={error} />
  ) : (
    <ReactFC
      type={type}
      dataSource={{
        ...dataSource,
        chart: {
          ...dataSource?.chart,
          labelFont: 'Muli',
          baseFont: 'Muli',
          outCnvBaseFont: 'Muli',
          baseChartMessageFont: 'Muli',
          legendItemFont: 'Muli'
        }
      }}
      width={width}
      height={height}
      dataFormat={dataFormat}
    />
  )
}

Chart.propTypes = {
  type: PropTypes.string,
  dataSource: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  dataFormat: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(CHART_ERROR)),
    PropTypes.bool
  ])
}

export default Chart
